import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';

const rejectionReasons = [
  { label: 'Se supera el nivel de endeudamiento objetivo de Fingo', value: 'OVER_INDEBTEDNESS_FINGO' },
  { label: 'Cliente con nivel de endeudamiento excesivo', value: 'OVER_INDEBTEDNESS_CLIENT' },
  { label: 'Cliente con nivel de endeudamiento excesivo con respecto a ventas', value: 'OVER_INDEBTEDNESS_CLIENT_SALES' },
  { label: 'Cliente con nivel de endeudamiento excesivo con respecto a patrimonio', value: 'OVER_INDEBTEDNESS_CLIENT_EQUITY' },
  { label: 'Existencia de procedimientos judiciales en curso', value: 'JUDICIAL_PROCEDEMENT' },
  { label: 'Existencia de procedimientos administrativos en curso', value: 'ADMINISTRATIVE_PROCEDURES' },
  { label: 'Antecedentes inexactos o insuficientes', value: 'INACCURATE_BACKGROUND' },
  { label: 'Cliente con morosidades o protestos según Ley 19.268', value: 'CLIENT_OVERDUE' },
  { label: 'Deudor con morosidades o protestos según Ley 19.268', value: 'DEBTOR_OVERDUE' },
  { label: 'Incumplimiento previo con Fingo o con una de sus filiales', value: 'PREVIOUS_NON_COMPLIANCE' },
  { label: 'Conflicto de interés en virtud de una causa legal', value: 'INTEREST_CONFLICT' },
  { label: 'No cumple con antigüedad mínima en la actividad ', value: 'SENIORITY' },
  { label: 'Presenta inestabilidad en sus ingresos', value: 'UNSTABLE_INCOME' },
  { label: 'Otro', value: 'OTHER' },
];

const RejectReasonDialog = (
  { row, updatePreoffer, setOpenOtherRejectionReason, setSelectedPreOfferEvaluation },
) => {
  const handleOpenDialog = (event) => {
    const { value } = event.target;
    if (value === 'OTHER') {
      setSelectedPreOfferEvaluation(row.id);
      setOpenOtherRejectionReason(true);
    }
    updatePreoffer(row.id, event, 'value');
  };
  return (
    <>
      <FormControl>
        <Select
          name="rejectionReason"
          value={row.rejectionReason}
          onChange={handleOpenDialog}
        >
          {rejectionReasons.map((type) => (
            <MenuItem value={type.value}>{type.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

RejectReasonDialog.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    otherRejectionReason: PropTypes.string.isRequired,
    rejectionReason: PropTypes.string.isRequired,
  }).isRequired,
  updatePreoffer: PropTypes.func.isRequired,
  setOpenOtherRejectionReason: PropTypes.func.isRequired,
  setSelectedPreOfferEvaluation: PropTypes.func.isRequired,
};

export default RejectReasonDialog;
