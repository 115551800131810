import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const NoRestrictionsMessage = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
    height="100%"
    textAlign="center"
    padding={4}
  >
    <AssignmentTurnedInIcon sx={{ fontSize: 40, color: 'gray' }} />
    <Typography variant="body2" color="textSecondary">
      Esta empresa no tiene restricciones registradas.
    </Typography>
  </Box>
);

export default NoRestrictionsMessage;
