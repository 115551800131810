import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  ASSIGN_EVALUATOR,
  GET_CLIENT_DEBTORS_LIST_EXCEL,
  EXPORT_INVOICES,
} from '@fingo/lib/graphql';
import Button from '@mui/material/Button';
import { DownloadExcelIconButton } from '@fingo/lib/components/buttons';
import { useExportDocument, useGetCurrenciesFromCompany } from '@fingo/lib/hooks';
import { CurrencyFilter } from '@fingo/lib/components/filters';
import { GET_PREOFFERS_REQUESTS } from '../../graphql/default/GetPreoffersRequestsQuery';
import { GET_PREOFFERS_REQUESTS_ORDERING } from '../../graphql/default/GetPreoffersRequestsOrderingQuery';
import RiskEvaluationBlacklist from './RiskEvaluationBlacklist';
import AcceptRiskEvaluationDialog from './dialogs/AcceptRiskEvaluationsDialog';
import RiskEvaluationReject from './dialogs/RejectRiskEvaluationsDialog';
import AddRestrictionsDialog from './dialogs/AddRestrictionsDialog';
import SendToPendingDocuments from './dialogs/SendToPendingDocuments';
import RiskEvaluationEmail from './RiskEvaluationEmail';

const EvaluationActions = (
  {
    filter,
    masterEntity,
    selectedDocuments,
    setSelectedDocuments,
    documentType,
    data,
    currency,
    setCurrency,
  },
) => {
  const companyId = masterEntity.id;
  const companyRut = masterEntity.rut;
  const companyName = masterEntity.name;
  const currencies = useGetCurrenciesFromCompany({ masterEntity });
  const {
    exportDocuments: exportInvoices,
    loading: exportingDocuments,
  } = useExportDocument(EXPORT_INVOICES, {
    companyId,
    hasEvaluatingPreoffer: true,
  });
  const {
    exportDocuments,
    loading,
  } = useExportDocument(
    GET_CLIENT_DEBTORS_LIST_EXCEL,
    { clientRut: masterEntity.rut },
    'ventas.xlsx',
    (_data) => _data.getClientDebtorsListExcel.url,
  );
  const [assignEvaluator] = useMutation(
    ASSIGN_EVALUATOR,
    {
      refetchQueries: [
        GET_PREOFFERS_REQUESTS,
        GET_PREOFFERS_REQUESTS_ORDERING,
      ],
    },
  );

  const documentsToEvaluate = useMemo(
    () => data?.filter((doc) => selectedDocuments.includes(doc.id)) ?? [],
    [selectedDocuments, data],
  );
  const disableEmailButton = useMemo(
    () => {
      if (!documentsToEvaluate.length) return true;
      for (let i = 0; i < documentsToEvaluate.length; i += 1) {
        if (!documentsToEvaluate[i].riskEvaluationModel) return true;
      }
      return false;
    },
    [selectedDocuments, data],
  );

  const pendingRestrictions = useMemo(() => {
    const [invoice] = documentsToEvaluate || [];
    const { company } = invoice || {};
    const { companyevaluationrestrictionsSet = [] } = company || {};
    return companyevaluationrestrictionsSet.some(
      ({ status, restrictionType }) => restrictionType === 'Risk' && ['CREATED', 'PENDING'].includes(status),
    );
  }, [documentsToEvaluate]);
  return (
    <>
      {filter === 'invoice' && (
      <DownloadExcelIconButton
        downloadFunction={exportInvoices}
        loading={exportingDocuments}
        title="Exportar Facturas en Evaluación"
      />
      )}
      <DownloadExcelIconButton
        downloadFunction={exportDocuments}
        title="Exportar Lista Deudores"
        loading={loading}
      />
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => assignEvaluator({
          variables: {
            documentId: data?.[0].id,
            modelName: filter,
            assign: true,
          },
        })}
        disabled={!data.length}
      >
        Asignar Evaluación
      </Button>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={() => assignEvaluator({
          variables: {
            documentId: data?.[0].id,
            modelName: filter,
            assign: false,
          },
        })}
        disabled={!data.length}
      >
        Desasignar Evaluación
      </Button>
      <AddRestrictionsDialog
        companyId={companyId}
        companyName={companyName}
        documentType={documentType}
        disabled={!data.length}
      />
      <AcceptRiskEvaluationDialog
        companyName={companyName}
        filter={filter}
        setSelectedDocuments={setSelectedDocuments}
        documentsToEvaluate={documentsToEvaluate}
        disabled={(!documentsToEvaluate.length) || (filter === 'invoice' && pendingRestrictions)}
      />
      {filter === 'invoice' && (
        <SendToPendingDocuments
          disabled={!documentsToEvaluate.length || !pendingRestrictions}
          documentsToEvaluate={documentsToEvaluate}
        />
      )}
      <RiskEvaluationReject
        companyName={companyName}
        filter={filter}
        setSelectedDocuments={setSelectedDocuments}
        documentsToEvaluate={documentsToEvaluate}
        disabled={!selectedDocuments.length}
      />
      <RiskEvaluationEmail
        companyName={companyName}
        modelName={filter}
        documentsToEvaluate={documentsToEvaluate}
        disabled={disableEmailButton}
      />
      <RiskEvaluationBlacklist
        companyRut={companyRut}
        companyName={companyName}
      />
      <CurrencyFilter currencies={currencies} currency={currency} setCurrency={setCurrency} />
    </>
  );
};

EvaluationActions.propTypes = {
  filter: PropTypes.oneOf(['invoice', 'purchaseOrder']).isRequired,
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rut: PropTypes.string.isRequired,
  }).isRequired,
  selectedDocuments: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company: PropTypes.shape({
        companyevaluationrestrictionsSet: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
          }),
        ),
      }).isRequired,
    }),
  ).isRequired,
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
};

export default EvaluationActions;
