import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState } from '@fingo/lib/hooks';
import ModelTraining from '@mui/icons-material/ModelTraining';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import PropTypes from 'prop-types';
import RiskPredictionResults from './automaticEvaluation/RiskPredictionResults';

const RiskPredictionDialog = ({
  riskEvaluationId,
}) => {
  const [open, toggleOpen] = useBooleanState();
  return (
    <>
      <IconButton onClick={toggleOpen} color="primary" disabled={!riskEvaluationId}>
        <ModelTraining />
      </IconButton>
      <FingoDialog open={open} handleClose={toggleOpen}>
        <RiskPredictionResults
          riskEvaluationId={riskEvaluationId}
        />
      </FingoDialog>
    </>
  );
};

RiskPredictionDialog.propTypes = {
  riskEvaluationId: PropTypes.string,
};

RiskPredictionDialog.defaultProps = {
  riskEvaluationId: null,
};

export default RiskPredictionDialog;
