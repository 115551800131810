import { useQuery } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { GET_RULE_RESULTS } from '@fingo/lib/graphql';
import { useBooleanState } from '@fingo/lib/hooks';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import riskEvaluationResultsColumns from '../../constants/risk-evaluations-results-columns';

const RiskResults = ({ riskEvaluation, documentId, documentType }) => {
  const [open, toggleOpen] = useBooleanState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedModel, setSelectedModel] = useState('Tree');
  const { loading, data: riskData } = useQuery(GET_RULE_RESULTS, {
    variables: {
      documentId,
      modelName: documentType,
    },
    skip: !open,
  });
  const riskResults = riskData?.getRuleResults || [];
  const retrieveNamesToFilter = (index) => (
    {
      0: ['client', 'client_debtor'],
      1: ['debtor', 'client'],
      2: ['client_debtor', 'debtor'],
    }[selectedTab][index]
  );

  const filterBySelectedModel = (resultsList) => resultsList.filter(
    (result) => result.modelType === selectedModel,
  ).filter((rule) => rule.name.startsWith(retrieveNamesToFilter(0))
    && !rule.name.startsWith(retrieveNamesToFilter(1))) || [];

  const rasResultColor = (rasResult) => {
    if (rasResult === null) return null;
    if (rasResult < 0.5) return red[700];
    return green[700];
  };

  const retrieveRASIcon = (result) => (
    <Typography sx={{ color: rasResultColor(result), fontWeight: 'bold' }}>
      {result}
    </Typography>
  );

  const retrieveTreeIcon = (result) => (
    result ? <CheckCircle sx={{ color: green[700] }} /> : (<Cancel sx={{ color: red[700] }} />)
  );

  const retrieveTreeIconResult = () => {
    if (riskEvaluation === null) return (<Cancel />);
    return retrieveTreeIcon(riskEvaluation?.treeClientDebtorResult
      && riskEvaluation?.treeClientResult
      && riskEvaluation?.treeDebtorResult);
  };

  const retrieveRasIconResult = () => {
    if (riskEvaluation?.rasClientDebtorResult
      && riskEvaluation?.rasClientResult
      && riskEvaluation?.rasDebtorResult
    ) {
      return retrieveRASIcon((riskEvaluation.rasDebtorResult * 0.4
      + riskEvaluation.rasClientResult * 0.4
      + riskEvaluation.rasClientDebtorResult * 0.2
      ).toFixed(2));
    }
    return (<Typography>n/a</Typography>);
  };

  const retrieveTabIcon = (tab) => {
    const riskResultByModel = {
      Tree: {
        client: 'treeClientResult',
        debtor: 'treeDebtorResult',
        clientDebtor: 'treeClientDebtorResult',
      },
      RAS: {
        client: 'rasClientResult',
        debtor: 'rasDebtorResult',
        clientDebtor: 'rasClientDebtorResult',
      },
    }[selectedModel][tab];
    if (selectedModel === 'RAS') {
      return retrieveRASIcon(riskEvaluation?.[riskResultByModel]);
    }
    return retrieveTreeIcon(riskEvaluation?.[riskResultByModel]);
  };

  return (
    <>
      <Button
        size="small"
        onClick={toggleOpen}
        disabled={riskEvaluation === null}
      >
        {!loading ? (
          <>
            {retrieveTreeIconResult()}
            {retrieveRasIconResult()}
          </>
        ) : null}
      </Button>
      <FingoDialog
        title="Resultados Motor de Riesgo"
        open={open}
        handleClose={toggleOpen}
        maxWidth="lg"
        fullWidth
      >
        <Tabs
          variant="fullWidth"
          value={selectedModel}
          onChange={(_, value) => {
            setSelectedModel(value);
          }}
        >
          <Tab
            label="Arbol"
            value="Tree"
          />
          <Tab
            label="RAS"
            value="RAS"
          />
        </Tabs>
        <Tabs
          variant="fullWidth"
          value={selectedTab}
          onChange={(_, value) => {
            setSelectedTab(value);
          }}
        >
          <Tab
            label="Cliente"
            icon={retrieveTabIcon('client')}
          />
          <Tab
            label="Deudor"
            icon={retrieveTabIcon('debtor')}
          />
          <Tab
            label="Relación"
            icon={retrieveTabIcon('clientDebtor')}
          />
        </Tabs>
        <FingoDataGrid
          rowHeight={50}
          rowCount={filterBySelectedModel(riskResults).length}
          rows={filterBySelectedModel(riskResults)}
          columns={riskEvaluationResultsColumns(selectedModel)}
          page={0}
          pageSize={filterBySelectedModel(riskResults).length}
        />
      </FingoDialog>
    </>
  );
};

RiskResults.propTypes = {
  riskEvaluation: PropTypes.shape({
    treeClientDebtorResult: PropTypes.bool.isRequired,
    treeClientResult: PropTypes.bool.isRequired,
    treeDebtorResult: PropTypes.bool.isRequired,
    rasClientDebtorResult: PropTypes.number.isRequired,
    rasClientResult: PropTypes.number.isRequired,
    rasDebtorResult: PropTypes.number.isRequired,
  }),
  documentId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
};

RiskResults.defaultProps = {
  riskEvaluation: null,
};

export default RiskResults;
