import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useBooleanState, useTextFieldInput } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import { ADD_COMPANIES_TO_BLACKLIST, GET_COMPANY_EVALUATIONS, GET_COMPANY_ORDERING_EVALUATIONS } from '@fingo/lib/graphql';

const RiskEvaluationBlacklist = ({ companyName, companyRut }) => {
  const [open, handleOpen] = useBooleanState();
  const [blacklistType, setBlacklistType] = useState('Blacklist');
  const [comments, setComments, resetComments] = useTextFieldInput('');
  const [addCompaniesToBlacklist, { loading }] = useMutation(ADD_COMPANIES_TO_BLACKLIST, {
    variables: { ruts: [companyRut], comments, blacklistType },
    onCompleted: () => {
      handleOpen(false);
      resetComments();
    },
    refetchQueries: [
      GET_COMPANY_EVALUATIONS,
      GET_COMPANY_ORDERING_EVALUATIONS,
    ],
  });
  return (
    <>
      <Tooltip title="Agregar Empresa a Blacklist">
        <span>
          <IconButton
            onClick={handleOpen}
            sx={{
              maxWidth: '39px',
              maxHeight: '39px',
              borderRadius: 2,
              backgroundColor: 'red',
              color: 'white',
              '&.Mui-disabled': {
                color: null,
                backgroundColor: 'grey',
              },
              '&:hover': {
                backgroundColor: '#d32f2f',
                color: 'white',
              },
            }}
          >
            <RemoveCircle />
          </IconButton>
        </span>
      </Tooltip>
      <FingoDialog
        key="blacklist-dialog"
        id="blacklist-dialog"
        title="Agregar Empresa a Blacklist"
        open={open}
        handleClose={handleOpen}
        maxWidth="md"
        fullWidth
      >
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography>
            ¿Por qué quieres agregar a la empresa {companyName} a la blacklist?
          </Typography>
          <TextField
            variant="outlined"
            name="rejectionComments"
            label="Comentarios"
            value={comments}
            onChange={setComments}
            multiline
            fullWidth
            rows={4}
          />
          <Select
            name="bank"
            value={blacklistType}
            onChange={(e) => setBlacklistType(e.target.value)}
          >
            <MenuItem value="Blacklist">Blacklist</MenuItem>
            <MenuItem value="Watchlist">Watchlist</MenuItem>
          </Select>
          <Stack direction="row" alignItems="center" spacing={1}>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              onClick={addCompaniesToBlacklist}
              loading={loading}
              sx={{ marginRight: 1 }}
            >
              Agregar a la Blacklist
            </LoadingButton>
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={{ marginLeft: 1 }}
              onClick={() => {
                handleOpen(false);
              }}
            >
              Cancelar
            </Button>
          </Stack>
        </Stack>
      </FingoDialog>
    </>
  );
};

RiskEvaluationBlacklist.propTypes = {
  companyRut: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
};

export default RiskEvaluationBlacklist;
