import React from 'react';
import Alert from '@mui/material/Alert';

const RiskVariablesAlert = ({ variables }) => {
  const errortMapArray = [
    { id: 'client_dicom_response', label: 'Dicom Cliente' },
    { id: 'debtor_dicom_response', label: 'Dicom Deudor' },
    { id: 'client_current_behavior_response', label: 'Comportamiento Vigente Cliente' },
    { id: 'debtor_current_behavior_response', label: 'Comportamiento Vigente Deudor' },
    { id: 'client_business_profile_response', label: 'Perfil Comercial Cliente' },
    { id: 'debtor_business_profile_response', label: 'Perfil Comercial Deudor' },
  ];
  return (errortMapArray.map((val) => {
    const founded = variables.find((e) => e.name === val.id)?.value === 'true';
    return (!founded ? <Alert key={val.id} severity="error">Respuesta {val.label} no válida</Alert> : null);
  }));
};

export default RiskVariablesAlert;
