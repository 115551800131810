import React from 'react';
import PropTypes from 'prop-types';
import { CurrencyFilter } from '@fingo/lib/components/filters';

const CreditLineEvaluationActions = ({ currency, setCurrency }) => (
  <>
    <CurrencyFilter currency={currency} setCurrency={setCurrency} />
  </>
);

CreditLineEvaluationActions.propTypes = {
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
};

export default CreditLineEvaluationActions;
