import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { getTimeDiffOnlyHoursMinutes, formatDateTime } from '@fingo/lib/helpers';
import Help from '@mui/icons-material/Help';
import { SLAResolutionType } from '@fingo/lib/propTypes/SLAResolutionType';
import { slaResolutionHelper } from '../../helpers/sla-resolution';

const SLAComponent = ({ row, documentType, showTooltip }) => {
  const slaResolution = slaResolutionHelper(row, documentType);

  const estimatedResolution = slaResolution?.estimatedResolution;
  const slaHours = estimatedResolution?.sla;

  const slaTooltip = useMemo(() => {
    if (!estimatedResolution) return 'No hay regla asociada para este SLA (Contactar Soporte)';
    const { clientIsNew, receiverIsNew, clientHasCreditLine } = estimatedResolution;
    const { minAmount, maxAmount } = estimatedResolution;
    const { name } = estimatedResolution.requestingPlatform.masterEntity;
    return (
      <>
        <Typography>Tipo Cliente: {clientIsNew ? 'Nuevo' : 'Antiguo'}</Typography>
        <Typography>Tipo Deudor: {receiverIsNew ? 'Nuevo' : 'Antiguo'}</Typography>
        <Typography>Linea Disp. Cliente: {clientHasCreditLine ? 'Si' : 'No'}</Typography>
        <Typography>Monto Operación: {minAmount.chileFormat}-{maxAmount.chileFormat}</Typography>
        <Typography>Plataforma: {name}</Typography>
      </>
    );
  }, [estimatedResolution]);

  const slaDatetime = useMemo(() => {
    if (!slaResolution) return <></>;
    const dt = moment(slaResolution.estimatedResolutionDatetime);
    const dif = dt.diff(moment());
    const color = dif > 0 ? 'success.main' : 'error.main';
    const text = dif > 0 ? 'Quedan' : 'Hace';
    return (
      <>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Tooltip title={`El tiempo restante solamente incluye horas hábiles en días laborales. Fecha y hora de término: ${formatDateTime(dt)}`}>
            <Help sx={{ width: '15px' }} />
          </Tooltip>
          <Typography
            color={color}
            sx={{ fontWeight: 'bold', fontSize: '13px' }}
          >{text} {getTimeDiffOnlyHoursMinutes(dt)}
          </Typography>
        </Stack>
      </>
    );
  }, [slaResolution]);

  if (!slaResolution) {
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Tooltip title="No hay SLA definido para esta operación. Si crees que hay problemas contactate con soporte">
          <Help sx={{ width: '15px' }} />
        </Tooltip>
        <Typography variant="h6" color="text.main">SIN SLA</Typography>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" justifyContent="center">
      {slaDatetime}
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography
          variant="subtitle"
          color="text.main"
        >
          Total: {slaHours} {slaHours === 1 ? 'hora hábil' : 'horas hábiles'}
        </Typography>
        {showTooltip && (
        <Tooltip title={slaTooltip}>
          <Help sx={{ width: '15px' }} />
        </Tooltip>
        )}
      </Stack>
    </Stack>

  );
};

SLAComponent.propTypes = {
  showTooltip: PropTypes.bool,
  documentType: PropTypes.oneOf(['invoice', 'preoffer', 'preofferEvaluationRequest', 'purchaseorder', 'slaResolution']),
  row: PropTypes.shape({
    SLAResolutionType,
    slaResolution: PropTypes.shape({
      SLAResolutionType,
    }),
    preofferevaluationrequest: PropTypes.shape({
      slaResolution: PropTypes.shape({
        SLAResolutionType,
      }),
    }),
    orderingsimulation: PropTypes.shape({
      slaResolution: PropTypes.shape({
        SLAResolutionType,
      }),
    }),
    preoffer: PropTypes.shape({
      preofferevaluationrequest: PropTypes.shape({
        slaResolution: PropTypes.shape({
          SLAResolutionType,
        }),
      }),
    }),
  }).isRequired,
};

SLAComponent.defaultProps = {
  documentType: 'slaResolution',
  showTooltip: false,
};

export default SLAComponent;
